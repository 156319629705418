import React from "react"
import { Link } from "gatsby"
import Lottie from "../../components/lottie"

function Footer({ footerType }) {
  const DonateLottie = require("../../images/lottie/donate.json")

  return (
    <>
      {footerType === "fullWidth" ? (
        <footer className="border-t border-wuiSlate-200 bg-wuiSlate-100 ">
          <div className="py-4 text-sm">
            <div className="container px-8 mx-auto">
              <div className="grid items-center grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
                <div className="flex items-center justify-between col-span-4 gap-3 lg:justify-start ">
                  <a
                    id="Windui"
                    aria-label="Wind UI logo"
                    aria-current="page"
                    className="flex items-center gap-2 text-lg font-bold focus:outline-none"
                    href="/"
                  >
                    <svg
                      className="h-6 overflow-visible group transform-gpu"
                      viewBox="0 0 186 49"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g className="animate-spin-wind" transform-origin="24 24">
                        <path
                          d="M4.40039 5.18035L23.9998 25L33.7952 15.0945L21.5488 2.7018C16.6468 -2.25529 4.40039 5.18035 4.40039 5.18035Z"
                          fill="#10B981"
                        ></path>
                        <path
                          d="M4.40072 44.8196L24.0001 25L14.2048 15.0945L1.94971 27.4785C-2.9523 32.4356 4.40072 44.8196 4.40072 44.8196Z"
                          fill="#10B981"
                        ></path>
                        <path
                          d="M24.0001 25C19.521 16.9534 14.2048 15.0945 14.2048 15.0945L1.94971 27.4785C1.94971 27.4785 4.40072 25 24.0001 25Z"
                          fill="#059669"
                        ></path>
                        <path
                          d="M43.5994 44.8196L24 25L14.2046 34.9055L26.451 47.2895C31.353 52.2465 43.5994 44.8109 43.5994 44.8109V44.8196Z"
                          fill="#10B981"
                        ></path>
                        <path
                          d="M24 24.9999C16.0428 29.5294 14.2046 34.9054 14.2046 34.9054L26.451 47.2894C26.451 47.2894 24 44.8108 24 24.9912V24.9999Z"
                          fill="#059669"
                        ></path>
                        <path
                          d="M43.5994 5.1803L24 24.9999L33.7954 34.9054L46.0418 22.5214C50.9438 17.5643 43.5908 5.1803 43.5908 5.1803H43.5994Z"
                          fill="#10B981"
                        ></path>
                        <path
                          d="M24.0003 25C28.4794 33.0465 33.7957 34.9055 33.7957 34.9055L46.0421 22.5215C46.0421 22.5215 43.5911 25 23.9917 25H24.0003Z"
                          fill="#059669"
                        ></path>
                        <path
                          d="M23.9998 25C31.957 20.4705 33.7952 15.0945 33.7952 15.0945L21.5488 2.70178C21.5488 2.70178 23.9998 5.18033 23.9998 25Z"
                          fill="#059669"
                        ></path>
                      </g>
                      <g>
                        <path
                          d="M71.1123 38.0001L63.7231 11.8182H68.824L73.5413 31.0583H73.7842L78.8212 11.8182H83.4618L88.5115 31.0711H88.7416L93.4589 11.8182H98.5598L91.1706 38.0001H86.4916L81.2501 19.6293H81.0456L75.7913 38.0001H71.1123Z"
                          fill="black"
                        ></path>
                        <path
                          d="M101.644 38.0001V18.3637H106.272V38.0001H101.644ZM103.971 15.5768C103.238 15.5768 102.607 15.3339 102.079 14.8481C101.55 14.3537 101.286 13.7614 101.286 13.0711C101.286 12.3722 101.55 11.7799 102.079 11.2941C102.607 10.7998 103.238 10.5526 103.971 10.5526C104.712 10.5526 105.343 10.7998 105.863 11.2941C106.391 11.7799 106.655 12.3722 106.655 13.0711C106.655 13.7614 106.391 14.3537 105.863 14.8481C105.343 15.3339 104.712 15.5768 103.971 15.5768Z"
                          fill="black"
                        ></path>
                        <path
                          d="M115.659 26.4944V38.0001H111.031V18.3637H115.454V21.7003H115.684C116.136 20.6009 116.856 19.7273 117.845 19.0796C118.842 18.4319 120.073 18.108 121.539 18.108C122.894 18.108 124.075 18.3978 125.08 18.9773C126.095 19.5569 126.879 20.3964 127.433 21.4958C127.995 22.5952 128.272 23.929 128.264 25.4972V38.0001H123.636V26.2131C123.636 24.9006 123.295 23.8736 122.613 23.1322C121.94 22.3907 121.007 22.0199 119.813 22.0199C119.004 22.0199 118.284 22.1989 117.653 22.5569C117.031 22.9063 116.541 23.4134 116.183 24.0782C115.833 24.743 115.659 25.5484 115.659 26.4944Z"
                          fill="black"
                        ></path>
                        <path
                          d="M140.255 38.3452C138.712 38.3452 137.332 37.9489 136.113 37.1563C134.894 36.3637 133.931 35.2131 133.224 33.7046C132.516 32.1961 132.163 30.3637 132.163 28.2074C132.163 26.0256 132.521 24.1847 133.237 22.6847C133.961 21.1762 134.937 20.0384 136.164 19.2714C137.391 18.4958 138.759 18.108 140.268 18.108C141.418 18.108 142.364 18.304 143.106 18.6961C143.847 19.0796 144.436 19.5441 144.87 20.0895C145.305 20.6265 145.641 21.1336 145.88 21.6109H146.072V11.8182H150.712V38.0001H146.161V34.9063H145.88C145.641 35.3836 145.296 35.8907 144.845 36.4276C144.393 36.956 143.796 37.4077 143.055 37.7827C142.313 38.1577 141.38 38.3452 140.255 38.3452ZM141.546 34.5484C142.526 34.5484 143.362 34.2841 144.052 33.7557C144.742 33.2188 145.266 32.4731 145.624 31.5185C145.982 30.564 146.161 29.4518 146.161 28.1819C146.161 26.912 145.982 25.8083 145.624 24.8708C145.275 23.9333 144.755 23.2046 144.065 22.6847C143.383 22.1648 142.543 21.9049 141.546 21.9049C140.515 21.9049 139.654 22.1734 138.964 22.7103C138.274 23.2472 137.754 23.9887 137.404 24.9347C137.055 25.8807 136.88 26.9631 136.88 28.1819C136.88 29.4091 137.055 30.5043 137.404 31.4674C137.762 32.4219 138.286 33.1762 138.977 33.7302C139.676 34.2756 140.532 34.5484 141.546 34.5484Z"
                          fill="black"
                        ></path>
                      </g>
                      <path
                        d="M168.16 29.7889V18.411H172.788V38.0474H168.3V34.5573H168.096C167.653 35.6568 166.924 36.5559 165.91 37.2548C164.904 37.9536 163.664 38.3031 162.189 38.3031C160.903 38.3031 159.765 38.0175 158.776 37.4465C157.796 36.867 157.029 36.0275 156.475 34.9281C155.921 33.8201 155.644 32.482 155.644 30.9139V18.411H160.272V30.1979C160.272 31.4423 160.613 32.4309 161.295 33.1639C161.976 33.8968 162.871 34.2633 163.979 34.2633C164.661 34.2633 165.322 34.0971 165.961 33.7647C166.6 33.4323 167.124 32.938 167.533 32.2818C167.951 31.617 168.16 30.786 168.16 29.7889Z"
                        fill="#10B981"
                        className="translate-y-2 transition duration-[400ms] group-hover:translate-y-0"
                      ></path>
                      <path
                        d="M177.546 38.0474V18.411H182.174V38.0474H177.546Z"
                        fill="#10B981"
                        className="translate-y-2 transition delay-100 duration-[400ms] group-hover:translate-y-0"
                      ></path>
                      <path
                        d="M177.981 14.8954C178.509 15.3812 179.14 15.6241 179.873 15.6241C180.615 15.6241 181.245 15.3812 181.765 14.8954C182.294 14.4011 182.558 13.8088 182.558 13.1184C182.558 12.4196 182.294 11.8272 181.765 11.3415C181.245 10.8471 180.615 10.6 179.873 10.6C179.14 10.6 178.509 10.8471 177.981 11.3415C177.453 11.8272 177.188 12.4196 177.188 13.1184C177.188 13.8088 177.453 14.4011 177.981 14.8954Z"
                        fill="#10B981"
                        className="translate-y-2 transition delay-100 duration-[400ms] ease-[cubic-bezier(.25,-0.5,.75,1.5)] group-hover:translate-y-0"
                      ></path>
                    </svg>
                  </a>

                  <a
                    href="/legal/license"
                    aria-label="WindUI License"
                    className="items-center hidden gap-2 px-3 py-1 text-xs leading-5 rounded-full bg-wuiSlate-50 ring-1 ring-wuiSlate-200 lg:flex"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                      height={16}
                      width={16}
                    >
                      <circle cx={7} cy={7} r={5.992} stroke="currentColor" />
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.03879 5.83346C5.91868 5.49361 5.59456 5.25012 5.21358 5.25012H4.63025C4.147 5.25012 3.75525 5.64187 3.75525 6.12512V7.87512C3.75525 8.35837 4.147 8.75012 4.63025 8.75012H5.21358C5.59456 8.75012 5.91867 8.50664 6.03879 8.16679"
                      />
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10.2451 5.83346C10.125 5.49361 9.80086 5.25012 9.41988 5.25012H8.83655C8.3533 5.25012 7.96155 5.64187 7.96155 6.12512V7.87512C7.96155 8.35837 8.3533 8.75012 8.83655 8.75012H9.41988C9.80086 8.75012 10.125 8.50664 10.2451 8.16679"
                      />
                    </svg>
                    BY-SA 4.0{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                      />
                    </svg>
                    MIT
                  </a>
                </div>

                <p className="flex flex-col col-span-2 text-left lg:col-span-4 lg:text-center">
                  Wind UI © 2024
                </p>
                <nav
                  className="col-span-2 lg:col-span-4"
                  aria-labelledby="miniFooter-links-3-sub"
                >
                  <h3 className="sr-only" id="miniFooter-links-3-sub">
                    Get in touch
                  </h3>
                  <ul className="flex flex-wrap items-center justify-end gap-2 lg:gap-4">
                    {/* email */}
                    <li className="leading-6">
                      <a
                        href="mailto:info@wind-ui.com"
                        aria-label="Contact WindUI link"
                        target="_blank"
                        className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiEmerald-600 focus:text-wuiEmerald-700"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 48 48"
                          strokeWidth={4}
                          height={16}
                          width={16}
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M42.1446 3.29228C31.4981 5.11458 10.771 14.9926 3.74363 19.6897C2.56564 20.4771 2.456 21.9509 3.49933 22.9096C5.02119 24.308 7.84896 26.6424 12.9997 30.2566C12.9997 30.2566 13.2335 44.8334 15.9997 45.2566C19.3464 44.7034 22.9125 42.3041 25.9997 38.7566C29.8363 41.1872 32.3507 42.572 33.8274 43.3225C34.8505 43.8426 36.0535 43.6106 36.6164 42.6104C40.1706 36.2948 44.2355 16.3667 44.9924 5.99118C45.1129 4.33872 43.7777 3.01274 42.1446 3.29228Z"
                          />
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M39 9.5C39 9.5 25.4201 23.9288 19.3339 31.5776C19.1114 31.8572 18.9755 32.185 18.923 32.5385C18.6539 34.3519 17.6796 40.3812 16 45"
                          />
                        </svg>
                      </a>
                    </li>
                    {/* discord */}
                    <li className="leading-6">
                      <a
                        href="https://discord.gg/qMR5C5fw2H"
                        aria-label="WindUI Discord"
                        target="_blank"
                        className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiEmerald-600 focus:text-wuiEmerald-700"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 48 48"
                          strokeWidth={4}
                          height={16}
                          width={16}
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M20.4586 10.7313C19.9276 10.8263 19.3774 10.5355 19.2039 10.0246L18.7463 8.67799C18.6085 8.27246 18.227 7.99738 17.799 8.0123C14.297 8.13429 11.2436 9.11209 9.91377 9.60297C9.44373 9.77648 9.04388 10.0897 8.76884 10.5085C7.33719 12.6885 2.88062 20.5146 3.00245 33.3092C3.00971 34.0719 3.32645 34.7989 3.92891 35.2667C5.29697 36.329 8.24199 38.276 12.9067 39.6837C13.5903 39.89 14.3396 39.6928 14.7428 39.1035C15.3465 38.2214 15.8832 37.1176 16.2626 36.2519C16.4769 35.763 17.0266 35.5115 17.5398 35.6583C19.201 36.1333 21.3452 36.4981 24.0129 36.4981C26.6718 36.4981 28.8074 36.1358 30.4623 35.663C30.9755 35.5164 31.5251 35.7678 31.7394 36.2566C32.1188 37.1216 32.6546 38.223 33.2572 39.1035C33.6604 39.6928 34.4097 39.89 35.0933 39.6837C39.758 38.276 42.703 36.329 44.0711 35.2667C44.6736 34.7989 44.9903 34.0719 44.9976 33.3092C45.1185 20.6113 40.7298 12.8071 39.264 10.5589C38.968 10.1049 38.5302 9.77763 38.0171 9.60311C36.584 9.11568 33.381 8.1479 30.2003 8.01497C29.7723 7.99708 29.3915 8.27246 29.2537 8.67799L28.7975 10.0206C28.6234 10.533 28.0704 10.8243 27.5377 10.7286C26.779 10.5924 25.5822 10.4409 24.0129 10.4409C22.4336 10.4409 21.2237 10.5944 20.4586 10.7313Z"
                          />
                          <ellipse
                            cx={16.5}
                            cy={25}
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            rx={4.5}
                            ry={5}
                          />
                          <ellipse
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            rx={4.5}
                            ry={5}
                            transform="matrix(-1 0 0 1 31.5 25)"
                          />
                        </svg>
                      </a>
                    </li>
                    {/* figma */}
                    <li>
                      <a
                        href="https://www.figma.com/community/file/1367454154463499766/windui-design-library"
                        target="blank"
                        aria-label="wind-ui figma"
                        className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiEmerald-600 focus:text-wuiEmerald-700"
                      >
                        <svg
                          fill="currentColor"
                          strokeWidth={4}
                          height={16}
                          width={16}
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 stroke-[4px]"
                        >
                          <path d="M 12.5 1 C 9.467 1 7 3.467 7 6.5 C 7 8.1579175 7.7405106 9.6431625 8.9042969 10.652344 C 7.1773693 11.580958 6 13.405492 6 15.5 C 6 17.360625 6.9316558 19.00426 8.3496094 20 C 6.9316558 20.99574 6 22.639375 6 24.5 C 6 27.533 8.467 30 11.5 30 C 14.533 30 17 27.533 17 24.5 L 17 19 L 17 12 L 20.5 12 C 23.533 12 26 9.533 26 6.5 C 26 3.467 23.533 1 20.5 1 L 12.5 1 z M 12.5 3 L 20.5 3 C 22.43 3 24 4.57 24 6.5 C 24 8.43 22.43 10 20.5 10 L 17 10 L 12.5 10 C 10.57 10 9 8.43 9 6.5 C 9 4.57 10.57 3 12.5 3 z M 11.5 12 L 12.5 12 L 15 12 L 15 19 L 11.5 19 C 9.57 19 8 17.43 8 15.5 C 8 13.57 9.57 12 11.5 12 z M 21 12 A 4 4 0 0 0 21 20 A 4 4 0 0 0 21 12 z M 11.5 21 L 15 21 L 15 24.5 C 15 26.43 13.43 28 11.5 28 C 9.57 28 8 26.43 8 24.5 C 8 22.57 9.57 21 11.5 21 z" />
                        </svg>
                      </a>
                    </li>
                    {/* facebook */}
                    <li className="leading-6">
                      <a
                        href="https://www.facebook.com/winduilibrary"
                        aria-label="WindUI Facebook"
                        target="_blank"
                        className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiEmerald-600 focus:text-wuiEmerald-700"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 48 48"
                          strokeWidth={4}
                          height={16}
                          width={16}
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M24 3C35.598 3 45 12.402 45 24C45 35.598 35.598 45 24 45C12.402 45 3 35.598 3 24C3 12.402 12.402 2.99999 24 3Z"
                          />
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M28.516 44.5139V32.0403H34.0549C34.943 32.0403 35.7424 31.4555 35.8722 30.5769C36.0869 29.1236 35.9957 27.8022 35.8568 26.8624C35.7293 25.9998 34.9605 25.4319 34.0885 25.4319H28.516C28.516 20.4384 29.347 19.7117 34.0309 19.6209C34.9296 19.6035 35.7364 19.0101 35.8668 18.1207C36.0861 16.6257 35.9987 15.3194 35.8611 14.4006C35.734 13.5518 34.9729 12.9984 34.1147 13.0059C25.8363 13.0781 21.1213 14.057 21.1213 25.4319H16.8325C15.999 25.4319 15.259 25.9543 15.1329 26.7782C14.9971 27.6658 14.915 28.9526 15.1417 30.5175C15.2727 31.4217 16.0859 32.0403 16.9995 32.0403H21.1213V44.805"
                          />
                        </svg>
                      </a>
                    </li>
                    {/* twitter */}
                    <li className="leading-6">
                      <a
                        href="https://twitter.com/winduilibrary"
                        aria-label="WindUI Twitter"
                        target="_blank"
                        className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiEmerald-600 focus:text-wuiEmerald-700"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 48 48"
                          strokeWidth={4}
                          height={16}
                          width={16}
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M27.369 7.50717C22.4287 10.8021 23.2334 15.5106 23.539 17.0918C14.3168 16.5491 8.28865 11.334 5.96378 8.9117C5.44577 8.37197 4.50351 8.49418 4.28702 9.20791C1.95396 16.8995 9.89992 23.9087 9.89992 23.9087C8.49519 23.9087 5.68987 23.4354 4.08405 23.1417C3.46137 23.0278 2.88346 23.5041 3.02027 24.1176C4.06567 28.8053 10.7979 33.9633 15.1954 33.8463C12.4119 36.4673 8.87541 37.4357 6.14662 37.7647C5.09466 37.8915 4.65927 39.4543 5.62749 39.8817C22.2576 47.2232 42.4949 34.659 41.4645 14.8813L44.8156 10.1496C45.2951 9.4726 44.7807 8.54445 43.9478 8.58371L39.0257 8.8157C37.0673 6.51687 31.8491 4.51917 27.369 7.50717Z"
                          />
                        </svg>
                      </a>
                    </li>
                    {/* reddit */}
                    <li className="leading-6">
                      <a
                        href="https://www.reddit.com/user/windui"
                        aria-label="WindUI Reddit"
                        target="_blank"
                        className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiEmerald-600 focus:text-wuiEmerald-700"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 48 48"
                          strokeWidth={4}
                          height={16}
                          width={16}
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.0975 18.8337C13.0385 17.1324 11.1515 16 9 16C5.68629 16 3 18.6863 3 22C3 23.8002 3.79281 25.4152 5.04833 26.515C4.36854 27.923 4 29.4314 4 31C4 38.732 12.9543 45 24 45C35.0457 45 44 38.732 44 31C44 29.4314 43.6315 27.923 42.9517 26.515C44.2072 25.4152 45 23.8002 45 22C45 18.6863 42.3137 16 39 16C36.8485 16 34.9615 17.1324 33.9025 18.8337C30.9829 17.6668 27.6027 17 24 17C20.3973 17 17.0171 17.6668 14.0975 18.8337Z"
                          />
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M41 7C41 9.20914 39.2091 11 37 11C34.7909 11 33 9.20914 33 7C33 4.79086 34.7909 3 37 3C39.2091 3 41 4.79086 41 7Z"
                          />
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M33.0454 6.33439C31.0784 5.75626 28.7232 5.8192 26.8069 7.35582C24.474 9.22656 24.0608 13.9689 24 16.9981"
                          />
                          <circle
                            cx={32}
                            cy={27}
                            r={3}
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <circle
                            r={3}
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            transform="matrix(-1 0 0 1 16 27)"
                          />
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M35 35C31.9374 37.2181 27.8745 38.5 24 38.5C20.1255 38.5 16.0626 37.2181 13 35"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </footer>
      ) : footerType === "normal" ? (
        <footer className="border-t border-wuiSlate-200 bg-wuiSlate-100">
          <div
            className={`container py-20 ${
              footerType === "fullWidth" ? "max-w-full" : " "
            }`}
          >
            <div className="grid grid-cols-4 gap-8 md:grid-cols-12">
              {/* Footer Logo */}
              <div className="col-span-4 md:col-span-4">
                <a
                  id="Windui"
                  aria-label="WindUI logo"
                  aria-current="page"
                  className="flex items-center gap-2 mb-6 text-lg font-bold focus:outline-none"
                  href="/"
                >
                  <svg
                    className="h-8 overflow-visible group transform-gpu lg:h-12"
                    viewBox="0 0 186 49"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g className="animate-spin-wind" transform-origin="24 24">
                      <path
                        d="M4.40039 5.18035L23.9998 25L33.7952 15.0945L21.5488 2.7018C16.6468 -2.25529 4.40039 5.18035 4.40039 5.18035Z"
                        fill="#10B981"
                      />
                      <path
                        d="M4.40072 44.8196L24.0001 25L14.2048 15.0945L1.94971 27.4785C-2.9523 32.4356 4.40072 44.8196 4.40072 44.8196Z"
                        fill="#10B981"
                      />
                      <path
                        d="M24.0001 25C19.521 16.9534 14.2048 15.0945 14.2048 15.0945L1.94971 27.4785C1.94971 27.4785 4.40072 25 24.0001 25Z"
                        fill="#059669"
                      />
                      <path
                        d="M43.5994 44.8196L24 25L14.2046 34.9055L26.451 47.2895C31.353 52.2465 43.5994 44.8109 43.5994 44.8109V44.8196Z"
                        fill="#10B981"
                      />
                      <path
                        d="M24 24.9999C16.0428 29.5294 14.2046 34.9054 14.2046 34.9054L26.451 47.2894C26.451 47.2894 24 44.8108 24 24.9912V24.9999Z"
                        fill="#059669"
                      />
                      <path
                        d="M43.5994 5.1803L24 24.9999L33.7954 34.9054L46.0418 22.5214C50.9438 17.5643 43.5908 5.1803 43.5908 5.1803H43.5994Z"
                        fill="#10B981"
                      />
                      <path
                        d="M24.0003 25C28.4794 33.0465 33.7957 34.9055 33.7957 34.9055L46.0421 22.5215C46.0421 22.5215 43.5911 25 23.9917 25H24.0003Z"
                        fill="#059669"
                      />
                      <path
                        d="M23.9998 25C31.957 20.4705 33.7952 15.0945 33.7952 15.0945L21.5488 2.70178C21.5488 2.70178 23.9998 5.18033 23.9998 25Z"
                        fill="#059669"
                      />
                    </g>
                    <g>
                      <path
                        d="M71.1123 38.0001L63.7231 11.8182H68.824L73.5413 31.0583H73.7842L78.8212 11.8182H83.4618L88.5115 31.0711H88.7416L93.4589 11.8182H98.5598L91.1706 38.0001H86.4916L81.2501 19.6293H81.0456L75.7913 38.0001H71.1123Z"
                        fill="black"
                      />
                      <path
                        d="M101.644 38.0001V18.3637H106.272V38.0001H101.644ZM103.971 15.5768C103.238 15.5768 102.607 15.3339 102.079 14.8481C101.55 14.3537 101.286 13.7614 101.286 13.0711C101.286 12.3722 101.55 11.7799 102.079 11.2941C102.607 10.7998 103.238 10.5526 103.971 10.5526C104.712 10.5526 105.343 10.7998 105.863 11.2941C106.391 11.7799 106.655 12.3722 106.655 13.0711C106.655 13.7614 106.391 14.3537 105.863 14.8481C105.343 15.3339 104.712 15.5768 103.971 15.5768Z"
                        fill="black"
                      />
                      <path
                        d="M115.659 26.4944V38.0001H111.031V18.3637H115.454V21.7003H115.684C116.136 20.6009 116.856 19.7273 117.845 19.0796C118.842 18.4319 120.073 18.108 121.539 18.108C122.894 18.108 124.075 18.3978 125.08 18.9773C126.095 19.5569 126.879 20.3964 127.433 21.4958C127.995 22.5952 128.272 23.929 128.264 25.4972V38.0001H123.636V26.2131C123.636 24.9006 123.295 23.8736 122.613 23.1322C121.94 22.3907 121.007 22.0199 119.813 22.0199C119.004 22.0199 118.284 22.1989 117.653 22.5569C117.031 22.9063 116.541 23.4134 116.183 24.0782C115.833 24.743 115.659 25.5484 115.659 26.4944Z"
                        fill="black"
                      />
                      <path
                        d="M140.255 38.3452C138.712 38.3452 137.332 37.9489 136.113 37.1563C134.894 36.3637 133.931 35.2131 133.224 33.7046C132.516 32.1961 132.163 30.3637 132.163 28.2074C132.163 26.0256 132.521 24.1847 133.237 22.6847C133.961 21.1762 134.937 20.0384 136.164 19.2714C137.391 18.4958 138.759 18.108 140.268 18.108C141.418 18.108 142.364 18.304 143.106 18.6961C143.847 19.0796 144.436 19.5441 144.87 20.0895C145.305 20.6265 145.641 21.1336 145.88 21.6109H146.072V11.8182H150.712V38.0001H146.161V34.9063H145.88C145.641 35.3836 145.296 35.8907 144.845 36.4276C144.393 36.956 143.796 37.4077 143.055 37.7827C142.313 38.1577 141.38 38.3452 140.255 38.3452ZM141.546 34.5484C142.526 34.5484 143.362 34.2841 144.052 33.7557C144.742 33.2188 145.266 32.4731 145.624 31.5185C145.982 30.564 146.161 29.4518 146.161 28.1819C146.161 26.912 145.982 25.8083 145.624 24.8708C145.275 23.9333 144.755 23.2046 144.065 22.6847C143.383 22.1648 142.543 21.9049 141.546 21.9049C140.515 21.9049 139.654 22.1734 138.964 22.7103C138.274 23.2472 137.754 23.9887 137.404 24.9347C137.055 25.8807 136.88 26.9631 136.88 28.1819C136.88 29.4091 137.055 30.5043 137.404 31.4674C137.762 32.4219 138.286 33.1762 138.977 33.7302C139.676 34.2756 140.532 34.5484 141.546 34.5484Z"
                        fill="black"
                      />
                    </g>
                    <path
                      d="M168.16 29.7889V18.411H172.788V38.0474H168.3V34.5573H168.096C167.653 35.6568 166.924 36.5559 165.91 37.2548C164.904 37.9536 163.664 38.3031 162.189 38.3031C160.903 38.3031 159.765 38.0175 158.776 37.4465C157.796 36.867 157.029 36.0275 156.475 34.9281C155.921 33.8201 155.644 32.482 155.644 30.9139V18.411H160.272V30.1979C160.272 31.4423 160.613 32.4309 161.295 33.1639C161.976 33.8968 162.871 34.2633 163.979 34.2633C164.661 34.2633 165.322 34.0971 165.961 33.7647C166.6 33.4323 167.124 32.938 167.533 32.2818C167.951 31.617 168.16 30.786 168.16 29.7889Z"
                      fill="#10B981"
                      className="translate-y-2 transition duration-[400ms] group-hover:translate-y-0"
                    />
                    <path
                      d="M177.546 38.0474V18.411H182.174V38.0474H177.546Z"
                      fill="#10B981"
                      className="translate-y-2 transition delay-100 duration-[400ms] group-hover:translate-y-0"
                    />
                    <path
                      d="M177.981 14.8954C178.509 15.3812 179.14 15.6241 179.873 15.6241C180.615 15.6241 181.245 15.3812 181.765 14.8954C182.294 14.4011 182.558 13.8088 182.558 13.1184C182.558 12.4196 182.294 11.8272 181.765 11.3415C181.245 10.8471 180.615 10.6 179.873 10.6C179.14 10.6 178.509 10.8471 177.981 11.3415C177.453 11.8272 177.188 12.4196 177.188 13.1184C177.188 13.8088 177.453 14.4011 177.981 14.8954Z"
                      fill="#10B981"
                      className="translate-y-2 transition delay-100 duration-[400ms] ease-[cubic-bezier(.25,-0.5,.75,1.5)] group-hover:translate-y-0"
                    />
                  </svg>
                </a>
                <p className="text-sm leading-relaxed text-wuiSlate-500">
                  Expertly made, responsive, accessible components in React and
                  HTML ready to be used on your website or app.
                </p>
                <p className="flex items-center gap-1 text-xs leading-relaxed text-wuiSlate-500">
                  Licensed by
                  <a
                    href="/legal/license"
                    className="flex items-center gap-1 transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-600 focus:text-wuiSlate-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                      height={16}
                      width={16}
                      className="w-4 h-4 "
                    >
                      <circle cx={7} cy={7} r={5.992} stroke="currentColor" />
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.03879 5.83346C5.91868 5.49361 5.59456 5.25012 5.21358 5.25012H4.63025C4.147 5.25012 3.75525 5.64187 3.75525 6.12512V7.87512C3.75525 8.35837 4.147 8.75012 4.63025 8.75012H5.21358C5.59456 8.75012 5.91867 8.50664 6.03879 8.16679"
                      />
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10.2451 5.83346C10.125 5.49361 9.80086 5.25012 9.41988 5.25012H8.83655C8.3533 5.25012 7.96155 5.64187 7.96155 6.12512V7.87512C7.96155 8.35837 8.3533 8.75012 8.83655 8.75012H9.41988C9.80086 8.75012 10.125 8.50664 10.2451 8.16679"
                      />
                    </svg>
                    BY-SA 4.0
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4 "
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                      />
                    </svg>
                    MIT
                  </a>
                </p>
              </div>
              {/* Footer Menu Items */}
              <div className="col-span-2">
                <h3 className="mb-6 text-sm font-medium uppercase text-wuiSlate-900">
                  Resources
                </h3>
                <ul className="flex flex-col space-y-4 text-sm list-none ">
                  <li>
                    <a
                      href="https://tailwindcss.com/docs/installation"
                      target="blank"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      Documentation
                    </a>
                  </li>
                  <li>
                    <a
                      href="/components/"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      Components
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.figma.com/community/file/1367454154463499766/windui-design-library"
                      target="blank"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      WindUI Figma
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://tailwindcss.com/"
                      target="blank"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      Tailwind CSS
                    </a>
                  </li>
                  <li>
                    <Link
                      to="/changelog"
                      target="blank"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      Changelog
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-span-2">
                <h3 className="mb-6 text-sm font-medium uppercase text-wuiSlate-900">
                  Help
                </h3>
                <ul className="flex flex-col space-y-4 text-sm list-none ">
                  <li>
                    <a
                      href="mailto:info@wind-ui.com"
                      aria-label="Contact WindUI link"
                      target="blank"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      Contact
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://discord.gg/qMR5C5fw2H"
                      target="_blank"
                      rel="noreferrer"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      Discord
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-span-2">
                <h3 className="mb-6 text-sm font-medium uppercase text-wuiSlate-900">
                  Socials
                </h3>
                <ul className="flex flex-col space-y-4 text-sm list-none ">
                  <li>
                    <a
                      href="https://www.facebook.com/winduilibrary"
                      target="_blank"
                      rel="noreferrer"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/winduilibrary"
                      target="_blank"
                      rel="noreferrer"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/windui/"
                      target="_blank"
                      rel="noreferrer"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      LinkedIn
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://codepen.io/windui"
                      target="_blank"
                      rel="noreferrer"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      CodePen
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.reddit.com/user/windui"
                      target="_blank"
                      rel="noreferrer"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      Reddit
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://dev.to/windui"
                      target="_blank"
                      rel="noreferrer"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      Dev
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-span-2">
                <h3 className="mb-6 text-sm font-medium uppercase text-wuiSlate-900">
                  Legal
                </h3>
                <ul className="flex flex-col space-y-4 text-sm list-none ">
                  <li>
                    <a
                      href="/legal/terms"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      Terms & Conditions
                    </a>
                  </li>
                  <li>
                    <a
                      href="/legal/privacy-policy"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="/legal/license"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      EULA
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Sub footer */}
          <div className="container py-10 text-sm font-semibold text-center border-t border-wuiSlate-200 text-wuiSlate-500">
            © Wind UI 2024.
          </div>
        </footer>
      ) : (
        <footer className="border-t border-wuiSlate-200 bg-wuiSlate-100">
          <div
            className={`container py-20 ${
              footerType === "fullWidth" ? "max-w-full" : " "
            }`}
          >
            <div className="grid grid-cols-4 gap-8 lg:grid-cols-12">
              {/* Donorbox */}
              <div className="col-span-4 p-6 py-8 mb-12 text-center rounded-lg shadow-xl bg-wuiEmerald-500/90 text-wuiEmerald-50 shadow-wuiEmerald-200 ring-1 ring-wuiEmerald-600 backdrop-blur-lg md:col-span-4 lg:mb-0 lg:-translate-y-40 lg:p-8 lg:py-16">
                <h3 className="relative mb-6 text-3xl font-medium text-wuiEmerald-50">
                  <span className="relative inline-block">
                    Support Our Mission
                  </span>
                </h3>
                <p className="mb-12 text-base leading-relaxed text-wuiEmerald-200 lg:text-lg">
                  Help us keep our UI component library free for everyone. Your
                  donation makes a difference in improving web experiences for
                  all.
                </p>
                <a
                  href="/support/"
                  className="relative inline-flex items-center justify-center h-12 gap-2 px-6 font-medium tracking-wide transition duration-300 rounded shadow-xl whitespace-nowrap bg-wuiEmerald-50 text-wuiEmerald-500 shadow-wuiEmerald-600/50 hover:bg-wuiEmerald-100 focus:bg-wuiEmerald-100 "
                >
                  <span>Support WindUI</span>
                  <span
                    className="absolute w-16 bottom-6 left-full "
                    aria-hidden="true"
                  >
                    <Lottie animationData={DonateLottie} />
                  </span>
                </a>
              </div>
              {/* Footer Menu Items */}
              <div className="col-span-2">
                <h3 className="mb-6 text-sm font-medium uppercase text-wuiSlate-900">
                  Resources
                </h3>
                <ul className="flex flex-col space-y-4 text-sm list-none ">
                  <li>
                    <a
                      href="https://tailwindcss.com/docs/installation"
                      target="blank"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      Documentation
                    </a>
                  </li>
                  <li>
                    <a
                      href="/components/"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      Components
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.figma.com/community/file/1367454154463499766/windui-design-library"
                      target="blank"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      WindUI Figma
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://tailwindcss.com/"
                      target="blank"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      Tailwind CSS
                    </a>
                  </li>
                  <li>
                    <Link
                      to="/changelog"
                      target="blank"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      Changelog
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-span-2">
                <h3 className="mb-6 text-sm font-medium uppercase text-wuiSlate-900">
                  Help
                </h3>
                <ul className="flex flex-col space-y-4 text-sm list-none ">
                  <li>
                    <a
                      href="mailto:info@wind-ui.com"
                      aria-label="Contact WindUI link"
                      target="blank"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      Contact
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://discord.gg/qMR5C5fw2H"
                      target="_blank"
                      rel="noreferrer"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      Discord
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-span-2">
                <h3 className="mb-6 text-sm font-medium uppercase text-wuiSlate-900">
                  Socials
                </h3>
                <ul className="flex flex-col space-y-4 text-sm list-none ">
                  <li>
                    <a
                      href="https://www.facebook.com/winduilibrary"
                      target="_blank"
                      rel="noreferrer"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/winduilibrary"
                      target="_blank"
                      rel="noreferrer"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/windui/"
                      target="_blank"
                      rel="noreferrer"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      LinkedIn
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://codepen.io/windui"
                      target="_blank"
                      rel="noreferrer"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      CodePen
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.reddit.com/user/windui"
                      target="_blank"
                      rel="noreferrer"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      Reddit
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://dev.to/windui"
                      target="_blank"
                      rel="noreferrer"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      Dev
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-span-2">
                <h3 className="mb-6 text-sm font-medium uppercase text-wuiSlate-900">
                  Legal
                </h3>
                <ul className="flex flex-col space-y-4 text-sm list-none ">
                  <li>
                    <a
                      href="/legal/terms"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      Terms & Conditions
                    </a>
                  </li>
                  <li>
                    <a
                      href="/legal/privacy-policy"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="/legal/license"
                      className="transition-colors duration-300 text-wuiSlate-500 hover:text-wuiSlate-900"
                    >
                      EULA
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Sub footer */}
          <div className="container flex flex-wrap items-center justify-center gap-4 py-10 text-sm border-t border-wuiSlate-200 text-wuiSlate-500">
            <span>© Wind UI 2024.</span>
            <span className="flex items-center gap-1">
              Licensed by
              <a
                href="/legal/license"
                className="flex items-center gap-1 transition-colors duration-300 text-wuiSlate-600 hover:text-wuiSlate-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                  height={16}
                  width={16}
                  className="w-4 h-4 "
                >
                  <circle cx={7} cy={7} r={5.992} stroke="currentColor" />
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.03879 5.83346C5.91868 5.49361 5.59456 5.25012 5.21358 5.25012H4.63025C4.147 5.25012 3.75525 5.64187 3.75525 6.12512V7.87512C3.75525 8.35837 4.147 8.75012 4.63025 8.75012H5.21358C5.59456 8.75012 5.91867 8.50664 6.03879 8.16679"
                  />
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.2451 5.83346C10.125 5.49361 9.80086 5.25012 9.41988 5.25012H8.83655C8.3533 5.25012 7.96155 5.64187 7.96155 6.12512V7.87512C7.96155 8.35837 8.3533 8.75012 8.83655 8.75012H9.41988C9.80086 8.75012 10.125 8.50664 10.2451 8.16679"
                  />
                </svg>
                BY-SA 4.0
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 "
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                  />
                </svg>
                MIT
              </a>
            </span>
          </div>
        </footer>
      )}
    </>
  )
}

export default Footer
