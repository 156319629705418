import React, { useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import { Link } from "gatsby"

import Lottie from "../lottie"

function Notification() {
  const [cookies, setCookie] = useCookies()
  const [dismiss, setDismiss] = useState(false)

  const today = new Date()

  const NotificationLottie = require("../../images/lottie/gift.json")

  useEffect(() => {
    const showNotificationTimer = setTimeout(function () {
      setCookie("showNotification", true, {
        path: "/",
        maxAge: today.getTime() + 3600000 * 24 * 7,
      })
    }, 3500)

    return () => {
      clearTimeout(showNotificationTimer)
    }
    //   }
  }, [])

  useEffect(() => {
    if (dismiss && !cookies.notified) {
      const notified = setTimeout(function () {
        setCookie("notified", true, {
          path: "/",
          maxAge: today.getTime() + 3600000 * 24 * 7,
        })
      }, 1000)
      return () => {
        clearTimeout(notified)
      }
    }
  }, [dismiss])

  return (
    <React.Fragment>
      {!cookies.notified && (
        <div
          className={` ${
            !cookies.showNotification &&
            "animate-notification animation-delay-3000 "
          } ${
            dismiss && "animate-notificationout animation-delay-300"
          } fixed bottom-6 right-6 z-50 flex w-96 max-w-[calc(100vw_-_3rem)] origin-top-left flex-row  items-center gap-4 rounded border border-wuiEmerald-500 bg-wuiEmerald-500/80 p-4 text-sm text-wuiEmerald-100 shadow-xl shadow-wuiEmerald-400/20 backdrop-blur-sm `}
          role="status"
        >
          <span className="h-16 w-16 shrink-0 rounded-full bg-white p-2">
            <Lottie animationData={NotificationLottie} />
          </span>
          <div className="flex flex-col gap-1">
            <h3 className="flex-1 font-semibold text-white">
              Check out the latest release!
            </h3>
            <ul className="flex list-inside list-disc flex-col gap-1">
              <li>Updated Tailwind to v.3.4.3</li>
              <li>
                <Link to="/components/form-elements/toggles/#TogglesWithIcons">
                  Added new Toggles variations!
                </Link>
              </li>
              <li>
                <a
                  href="https://www.figma.com/community/file/1367454154463499766/windui-design-library"
                  target="blank"
                >
                  WindUI Figma design library!
                </a>
              </li>
            </ul>
          </div>
          <button
            aria-label="Close"
            className="absolute -right-2 -top-2 inline-flex h-6 w-6 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full border border-wuiEmerald-500 bg-wuiEmerald-400 text-xs font-medium tracking-wide text-wuiEmerald-500 shadow-md shadow-wuiEmerald-600/60 transition duration-300 hover:bg-wuiEmerald-400 focus:bg-wuiEmerald-500 focus-visible:outline-none"
            onClick={() => setDismiss(true)}
          >
            <span className="relative only:-mx-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="white"
                strokeWidth="1.5"
                role="graphics-symbol"
                aria-labelledby="title-close-button desc-close-button"
              >
                <title id="title-close-button">Close Button</title>
                <desc id="desc-close-button">
                  Button that dismisses the notification of new components and
                  releases
                </desc>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </button>
        </div>
      )}
    </React.Fragment>
  )
}

export default Notification
