import React, { useState, useEffect, useRef } from "react"

import ColorPicker from "../colorpicker"

const Header = ({ showTheme, fullWidth }) => {
  const [showColorPicker, setShowColorPicker] = useState(false)
  const WrapperRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (WrapperRef.current && !WrapperRef.current.contains(event.target)) {
        setShowColorPicker(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [WrapperRef])

  return (
    <header className="border-b-1 sticky top-0 z-40 border-b border-wuiSlate-200 bg-white/90 backdrop-blur-sm">
      <div className={`container ${fullWidth ? "max-w-full" : " "}`}>
        <nav
          aria-label="main navigation"
          className="flex items-center justify-between font-medium"
          role="navigation"
        >
          <div className="flex items-center gap-6">
            {/* logo  */}
            <a
              id="WindUI"
              aria-label="WindUI logo"
              aria-current="page"
              className="flex items-center gap-2 text-lg font-bold focus:outline-none"
              href="/"
            >
              <svg
                className="group h-8 transform-gpu overflow-visible lg:h-12"
                viewBox="0 0 186 49"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g className="animate-spin-wind" transform-origin="24 24">
                  <path
                    d="M4.40039 5.18035L23.9998 25L33.7952 15.0945L21.5488 2.7018C16.6468 -2.25529 4.40039 5.18035 4.40039 5.18035Z"
                    fill="#10B981"
                  />
                  <path
                    d="M4.40072 44.8196L24.0001 25L14.2048 15.0945L1.94971 27.4785C-2.9523 32.4356 4.40072 44.8196 4.40072 44.8196Z"
                    fill="#10B981"
                  />
                  <path
                    d="M24.0001 25C19.521 16.9534 14.2048 15.0945 14.2048 15.0945L1.94971 27.4785C1.94971 27.4785 4.40072 25 24.0001 25Z"
                    fill="#059669"
                  />
                  <path
                    d="M43.5994 44.8196L24 25L14.2046 34.9055L26.451 47.2895C31.353 52.2465 43.5994 44.8109 43.5994 44.8109V44.8196Z"
                    fill="#10B981"
                  />
                  <path
                    d="M24 24.9999C16.0428 29.5294 14.2046 34.9054 14.2046 34.9054L26.451 47.2894C26.451 47.2894 24 44.8108 24 24.9912V24.9999Z"
                    fill="#059669"
                  />
                  <path
                    d="M43.5994 5.1803L24 24.9999L33.7954 34.9054L46.0418 22.5214C50.9438 17.5643 43.5908 5.1803 43.5908 5.1803H43.5994Z"
                    fill="#10B981"
                  />
                  <path
                    d="M24.0003 25C28.4794 33.0465 33.7957 34.9055 33.7957 34.9055L46.0421 22.5215C46.0421 22.5215 43.5911 25 23.9917 25H24.0003Z"
                    fill="#059669"
                  />
                  <path
                    d="M23.9998 25C31.957 20.4705 33.7952 15.0945 33.7952 15.0945L21.5488 2.70178C21.5488 2.70178 23.9998 5.18033 23.9998 25Z"
                    fill="#059669"
                  />
                </g>
                <g>
                  <path
                    d="M71.1123 38.0001L63.7231 11.8182H68.824L73.5413 31.0583H73.7842L78.8212 11.8182H83.4618L88.5115 31.0711H88.7416L93.4589 11.8182H98.5598L91.1706 38.0001H86.4916L81.2501 19.6293H81.0456L75.7913 38.0001H71.1123Z"
                    fill="black"
                  />
                  <path
                    d="M101.644 38.0001V18.3637H106.272V38.0001H101.644ZM103.971 15.5768C103.238 15.5768 102.607 15.3339 102.079 14.8481C101.55 14.3537 101.286 13.7614 101.286 13.0711C101.286 12.3722 101.55 11.7799 102.079 11.2941C102.607 10.7998 103.238 10.5526 103.971 10.5526C104.712 10.5526 105.343 10.7998 105.863 11.2941C106.391 11.7799 106.655 12.3722 106.655 13.0711C106.655 13.7614 106.391 14.3537 105.863 14.8481C105.343 15.3339 104.712 15.5768 103.971 15.5768Z"
                    fill="black"
                  />
                  <path
                    d="M115.659 26.4944V38.0001H111.031V18.3637H115.454V21.7003H115.684C116.136 20.6009 116.856 19.7273 117.845 19.0796C118.842 18.4319 120.073 18.108 121.539 18.108C122.894 18.108 124.075 18.3978 125.08 18.9773C126.095 19.5569 126.879 20.3964 127.433 21.4958C127.995 22.5952 128.272 23.929 128.264 25.4972V38.0001H123.636V26.2131C123.636 24.9006 123.295 23.8736 122.613 23.1322C121.94 22.3907 121.007 22.0199 119.813 22.0199C119.004 22.0199 118.284 22.1989 117.653 22.5569C117.031 22.9063 116.541 23.4134 116.183 24.0782C115.833 24.743 115.659 25.5484 115.659 26.4944Z"
                    fill="black"
                  />
                  <path
                    d="M140.255 38.3452C138.712 38.3452 137.332 37.9489 136.113 37.1563C134.894 36.3637 133.931 35.2131 133.224 33.7046C132.516 32.1961 132.163 30.3637 132.163 28.2074C132.163 26.0256 132.521 24.1847 133.237 22.6847C133.961 21.1762 134.937 20.0384 136.164 19.2714C137.391 18.4958 138.759 18.108 140.268 18.108C141.418 18.108 142.364 18.304 143.106 18.6961C143.847 19.0796 144.436 19.5441 144.87 20.0895C145.305 20.6265 145.641 21.1336 145.88 21.6109H146.072V11.8182H150.712V38.0001H146.161V34.9063H145.88C145.641 35.3836 145.296 35.8907 144.845 36.4276C144.393 36.956 143.796 37.4077 143.055 37.7827C142.313 38.1577 141.38 38.3452 140.255 38.3452ZM141.546 34.5484C142.526 34.5484 143.362 34.2841 144.052 33.7557C144.742 33.2188 145.266 32.4731 145.624 31.5185C145.982 30.564 146.161 29.4518 146.161 28.1819C146.161 26.912 145.982 25.8083 145.624 24.8708C145.275 23.9333 144.755 23.2046 144.065 22.6847C143.383 22.1648 142.543 21.9049 141.546 21.9049C140.515 21.9049 139.654 22.1734 138.964 22.7103C138.274 23.2472 137.754 23.9887 137.404 24.9347C137.055 25.8807 136.88 26.9631 136.88 28.1819C136.88 29.4091 137.055 30.5043 137.404 31.4674C137.762 32.4219 138.286 33.1762 138.977 33.7302C139.676 34.2756 140.532 34.5484 141.546 34.5484Z"
                    fill="black"
                  />
                </g>
                <path
                  d="M168.16 29.7889V18.411H172.788V38.0474H168.3V34.5573H168.096C167.653 35.6568 166.924 36.5559 165.91 37.2548C164.904 37.9536 163.664 38.3031 162.189 38.3031C160.903 38.3031 159.765 38.0175 158.776 37.4465C157.796 36.867 157.029 36.0275 156.475 34.9281C155.921 33.8201 155.644 32.482 155.644 30.9139V18.411H160.272V30.1979C160.272 31.4423 160.613 32.4309 161.295 33.1639C161.976 33.8968 162.871 34.2633 163.979 34.2633C164.661 34.2633 165.322 34.0971 165.961 33.7647C166.6 33.4323 167.124 32.938 167.533 32.2818C167.951 31.617 168.16 30.786 168.16 29.7889Z"
                  fill="#10B981"
                  className="translate-y-2 transition duration-[400ms] group-hover:translate-y-0"
                />
                <path
                  d="M177.546 38.0474V18.411H182.174V38.0474H177.546Z"
                  fill="#10B981"
                  className="translate-y-2 transition delay-100 duration-[400ms] group-hover:translate-y-0"
                />
                <path
                  d="M177.981 14.8954C178.509 15.3812 179.14 15.6241 179.873 15.6241C180.615 15.6241 181.245 15.3812 181.765 14.8954C182.294 14.4011 182.558 13.8088 182.558 13.1184C182.558 12.4196 182.294 11.8272 181.765 11.3415C181.245 10.8471 180.615 10.6 179.873 10.6C179.14 10.6 178.509 10.8471 177.981 11.3415C177.453 11.8272 177.188 12.4196 177.188 13.1184C177.188 13.8088 177.453 14.4011 177.981 14.8954Z"
                  fill="#10B981"
                  className="translate-y-2 transition delay-100 duration-[400ms] ease-[cubic-bezier(.25,-0.5,.75,1.5)] group-hover:translate-y-0"
                />
              </svg>
            </a>

            {/* tailwind version  */}
            <div className="hidden items-center gap-2 rounded-full bg-wuiSlate-50 px-3 py-1 text-xs leading-5 ring-1 ring-wuiSlate-200 lg:flex">
              <svg
                className="h-5 w-5"
                viewBox="0 0 24 24"
                fill="none"
                strokeWidth="1.5"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6 9.6C6.79987 6.40012 8.80013 4.8 12 4.8C16.8 4.8 17.4 8.4 19.8 9C21.4001 9.40012 22.8 8.80012 24 7.2C23.2001 10.3999 21.1999 12 18 12C13.2 12 12.6 8.4 10.2 7.8C8.59988 7.39987 7.2 7.99987 6 9.6ZM0 16.8C0.799875 13.6001 2.80013 12 6 12C10.8 12 11.4 15.6 13.8 16.2C15.4001 16.6001 16.8 16.0001 18 14.4C17.2001 17.5999 15.1999 19.2 12 19.2C7.2 19.2 6.6 15.6 4.2 15C2.59987 14.5999 1.2 15.1999 0 16.8Z"
                  stroke="currentColor"
                  fill="none"
                />
              </svg>

              <span>v.3.4.3</span>
            </div>
          </div>

          {/* <!-- Toggle menu button --> */}
          <a
            href="#"
            className="focus:outline-none"
            aria-expanded="false"
            aria-label="toggle navigation"
          ></a>

          <ul
            role="menubar"
            aria-label="windui"
            className="flex justify-center gap-2 md:gap-4 lg:gap-6"
          >
            <li role="none" className="navigation_menu-item">
              <a
                role="menuitem"
                aria-haspopup="false"
                tabIndex={0}
                className="browse_components flex items-center justify-center gap-2 px-2 py-8 transition-colors duration-300 hover:fill-wuiEmerald-500 hover:text-wuiEmerald-500 focus:outline-none lg:px-4"
                href="/components/"
                aria-label="wind-ui components"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
                  />
                </svg>
                <span className="hidden lg:block">Components</span>
              </a>
            </li>
            {showTheme && (
              <li
                role="none"
                className="navigation_menu-item relative"
                onClick={() => setShowColorPicker(!showColorPicker)}
                ref={WrapperRef}
              >
                <a
                  role="menuitem"
                  aria-haspopup="true"
                  tabIndex={0}
                  className="flex items-center justify-center gap-2 px-2 py-8 transition-colors duration-300 hover:fill-wuiEmerald-500 hover:text-wuiEmerald-500 focus:outline-none lg:px-4"
                  href="javascript:void(0)"
                  aria-label="color theme selector"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.098 19.902a3.75 3.75 0 005.304 0l6.401-6.402M6.75 21A3.75 3.75 0 013 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 003.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008z"
                    />
                  </svg>
                  <span className="hidden lg:block">Theme</span>
                </a>

                {showColorPicker && <ColorPicker />}
              </li>
            )}
            <li
              role="none"
              className={`flex items-center justify-center ${
                !showTheme ? "flex" : "hidden md:flex"
              }`}
            >
              <a
                role="menuitem"
                aria-haspopup="false"
                tabIndex={0}
                className="peer flex items-center justify-center gap-2 px-2 py-8 transition-colors duration-300 hover:fill-wuiEmerald-500 hover:text-wuiEmerald-500 focus:outline-none lg:px-4"
                href="https://www.figma.com/community/file/1367454154463499766/windui-design-library"
                target="blank"
                aria-label="wind-ui figma"
              >
                <svg
                  fill="currentColor"
                  width="800px"
                  height="800px"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                >
                  <path d="M 12.5 1 C 9.467 1 7 3.467 7 6.5 C 7 8.1579175 7.7405106 9.6431625 8.9042969 10.652344 C 7.1773693 11.580958 6 13.405492 6 15.5 C 6 17.360625 6.9316558 19.00426 8.3496094 20 C 6.9316558 20.99574 6 22.639375 6 24.5 C 6 27.533 8.467 30 11.5 30 C 14.533 30 17 27.533 17 24.5 L 17 19 L 17 12 L 20.5 12 C 23.533 12 26 9.533 26 6.5 C 26 3.467 23.533 1 20.5 1 L 12.5 1 z M 12.5 3 L 20.5 3 C 22.43 3 24 4.57 24 6.5 C 24 8.43 22.43 10 20.5 10 L 17 10 L 12.5 10 C 10.57 10 9 8.43 9 6.5 C 9 4.57 10.57 3 12.5 3 z M 11.5 12 L 12.5 12 L 15 12 L 15 19 L 11.5 19 C 9.57 19 8 17.43 8 15.5 C 8 13.57 9.57 12 11.5 12 z M 21 12 A 4 4 0 0 0 21 20 A 4 4 0 0 0 21 12 z M 11.5 21 L 15 21 L 15 24.5 C 15 26.43 13.43 28 11.5 28 C 9.57 28 8 26.43 8 24.5 C 8 22.57 9.57 21 11.5 21 z" />
                </svg>
              </a>
            </li>
            <li
              role="none"
              className={`flex items-center justify-center ${
                !showTheme ? "flex" : "hidden md:flex"
              }`}
            >
              <a
                role="menuitem"
                aria-haspopup="false"
                tabIndex={0}
                className="peer flex items-center justify-center gap-2 px-2 py-8 transition-colors duration-300 hover:fill-wuiEmerald-500 hover:text-wuiEmerald-500 focus:outline-none lg:px-4"
                href="https://discord.gg/qMR5C5fw2H"
                target="blank"
                aria-label="wind-ui discord channel"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                >
                  <path
                    d="M5.5 16C10.5 18.5 13.5 18.5 18.5 16"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.5 17.5L16.5 19.5C16.5 19.5 20.671 18.172 22 16C22 15 22.53 7.853 19 5.5C17.5 4.5 15 4 15 4L14 6H12"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.52827 17.5L7.52827 19.5C7.52827 19.5 3.35727 18.172 2.02827 16C2.02827 15 1.49827 7.853 5.02827 5.5C6.52827 4.5 9.02827 4 9.02827 4L10.0283 6H12.0283"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.5 14C7.672 14 7 13.105 7 12C7 10.895 7.672 10 8.5 10C9.328 10 10 10.895 10 12C10 13.105 9.328 14 8.5 14ZM15.5 14C14.672 14 14 13.105 14 12C14 10.895 14.672 10 15.5 10C16.328 10 17 10.895 17 12C17 13.105 16.328 14 15.5 14Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </a>
            </li>
            <li role="none" className="flex items-center justify-center">
              <a
                role="menuitem"
                aria-haspopup="false"
                tabIndex={0}
                href="/support"
                aria-label="wind-ui support page"
                className="hidden h-10 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none md:inline-flex"
              >
                <span className="order-2">Support us</span>
              </a>
              <a
                role="menuitem"
                aria-haspopup="false"
                tabIndex={0}
                href="/support"
                aria-label="wind-ui support page"
                className="peer flex items-center justify-center gap-2 px-2 py-8 transition-colors duration-300 hover:fill-wuiEmerald-500 hover:text-wuiEmerald-500 focus:outline-none md:hidden lg:px-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
